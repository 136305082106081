import React from 'react'

function Challenge() {
  return (
    <section className="container-fluid py-5">
    <div className="container">
      <div className="card mb-4 mb-lg-0 border-0 shadow-sm">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12 order-lg-1 mb-4 mb-lg-0">
            <div className="p-4">
              <div className="mb-4">
                <h2 className="text-primary">The Global Challenge of Air Pollution</h2>
              </div>
              <div className="support-caption">
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <i className="fa fa-globe text-primary me-2"></i>
                    <strong style={{ color: 'black', fontSize: '1.2rem' }}>Scope:</strong>
                    <span style={{ color: 'black', fontSize: '1.2rem' }}>
                      Air pollution is a critical global health concern affecting 99% of the world's population.
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-heartbeat text-danger me-2"></i>
                    <strong style={{ color: 'black', fontSize: '1.2rem' }}>Impact:</strong>
                    <span style={{ color: 'black', fontSize: '1.2rem' }}>
                      It leads to approximately 7 million premature deaths annually.
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-home text-warning me-2"></i>
                    <strong style={{ color: 'black', fontSize: '1.2rem' }}>Household Exposure:</strong>
                    <span style={{ color: 'black', fontSize: '1.2rem' }}>
                      Alarmingly, 2.4 billion individuals are exposed to hazardous levels of household air pollution.
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-exclamation-triangle text-danger me-2"></i>
                    <strong style={{ color: 'black', fontSize: '1.2rem' }}>Key Threat:</strong>
                    <span style={{ color: 'black', fontSize: '1.2rem' }}>
                      PM2.5 (particulate matter ≤ 2.5 micrometers) is a primary health risk, directly absorbed into the bloodstream upon inhalation.
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-stethoscope text-success me-2"></i>
                    <strong style={{ color: 'black', fontSize: '1.2rem' }}>Health Consequences:</strong>
                    <span style={{ color: 'black', fontSize: '1.2rem' }}>
                      PM2.5 exposure is linked to severe illnesses including stroke, heart disease, lung disease, and cancer.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2">
            <img src={require("../assets/img/image2.jpg")} className="img-fluid rounded" alt="Air Pollution Image" />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Challenge