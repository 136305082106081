import React from 'react'

function Team() {
  return (
    <div id="team" className="container-fluid team py-2">
    <div className="container py-5">
      <div className="mx-auto text-center mb-3" style={{ maxWidth: '900px' }}>
        <h5 className="section-title px-3">Team</h5>
        <h1 className="mb-0">Meet Team Members</h1>
      </div>
      <div className="row g-4">
        {/* Team Members */}
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/lawrence_nderu.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://ke.linkedin.com/in/dr-lawrence-nderu"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Lawrence Nderu</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Principal Instructor</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/james.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://ke.linkedin.com/in/james-moseti-934062246"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">James Moseti</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Data Analyst(Team Lead)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/ohanga.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/vincent-ochieng-602075216/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Vincent Ochieng'</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Backend Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/fidel.jpg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/fidel-castro-0074a824b/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Fidel Castro</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Frontend Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/gift.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/gift-nestah1/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Gift Nestah</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Backend Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/Adrian.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/adrian-baraka-b28b41309/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Adrian Baraka</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Frontend Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/christine.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/christine-awuor-993804252/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Odegi Christine</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Frontend Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="team-item">
            <div className="team-img">
              <div className="team-img-efects">
                <img
                  src={require("../assets/img/martin.jpeg")}
                  style={{ width: '300px', height: '300px' }}
                  className="img-fluid w-100 rounded-top"
                  alt="Image"
                />
              </div>
              <div className="team-icon rounded-pill p-2">
                <a
                  className="btn btn-square btn-primary rounded-circle mx-1"
                  href="https://www.linkedin.com/in/martin-gakumba-087aa3260/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="team-title text-center rounded-bottom p-4">
              <div className="team-title-inner">
                <h4 className="mt-3">Martin Gakumba</h4>
                <p className="mb-0" style={{fontWeight:'bold'}}>Frontend Designer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Team