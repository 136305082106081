import React from 'react';

const Footer = () => (
  <>
    {/* Footer Start */}
    <footer id="footer" className="container-fluid footer py-3 text-light bg-dark">
      <div className="container">
        <div className="row justify-content-center">
          {/* Get in Touch */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-item">
              <h4 className="mb-3 text-white mt-3">Get In Touch</h4>
              <p>
                <i className="fas fa-envelope me-2"></i>
                <a href="mailto:polluwatch62@gmail.com" className="text-decoration-none text-light">
                  polluwatch62@gmail.com
                </a>
              </p>
              <p>
                <i className="fas fa-phone me-2"></i>
                <a href="tel:+254759099417" className="text-decoration-none text-light">
                  +254 759 099 417
                </a>
              </p>
              <p>
                <i className="fas fa-building me-2"></i>
                <a href="https://jhubafrica.com/" className="text-decoration-none text-light">
                  JHUB Africa
                </a>
              </p>
            </div>
          </div>
          {/* Social Media Links */}
          <div className="col-md-6 col-lg-3">
            <div className="footer-item">
              <h4 className="mb-3 text-white mt-3">Follow Us</h4>
              <div className="d-flex">
                <a href="https://www.facebook.com/DiscoverJKUAT/" target="_blank" className="btn btn-outline-light btn-social me-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://x.com/JHUBAfrica" target="_blank" className="btn btn-outline-light btn-social me-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://ke.linkedin.com/company/jhubafrica" target="_blank" className="btn btn-outline-light btn-social me-2">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="https://www.instagram.com/discoverjkuat/?hl=en#" target="_blank" className="btn btn-outline-light btn-social me-2">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {/* Footer End */}

    {/* Copyright Start */}
    <div className="container-fluid text-center py-3 text-light bg-dark">
      <div className="container">
        <p className="mb-0">
          &copy;
          <a href="#" className="text-decoration-none text-light">PolluWatch</a>
          &middot; All rights reserved.
        </p>
      </div>
    </div>
    {/* Copyright End */}

    {/* Back to Top */}
    <a href="#" className="btn btn-primary btn-md-square back-to-top">
      <i className="fa fa-arrow-up"></i>
    </a>
  </>
);

export default Footer;
