import React from 'react';
import Header from './layouts/header';
import Navbar from './layouts/navbar';
import Footer from './layouts/footer';
import About from './layouts/About'
import Challenge from './layouts/Challenge'
import Solution from './layouts/Solution'
import TeamMembers from './layouts/Team'


const App = () => (
  <>
    <Header />
    <Navbar />
    <About/>
    <Challenge/>
    <Solution />
    <TeamMembers />
    <Footer />
  </>
);

export default App;
