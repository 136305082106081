import React from 'react'

function Solution() {
  return (
    <div id="about" className="container-fluid team py-2">
    <div className="container py-3">
      <div className="mx-auto text-center mb-3" style={{ maxWidth: '900px' }}>
        <h1 className="mb-0">FEATURES OF POLLUWATCH</h1>
      </div>

      <div
        id="featureCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="5000"
      >
        <div className="carousel-inner" style={{ color: 'black', fontSize: '20px', padding: '8px' }}>
          {/* Slide 1 */}
          <div className="carousel-item active">
            <div className="container">
              <div className="card mb-4 mb-lg-0">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-1 mb-4 mb-lg-0">
                    <div className="mb-3">
                      <h2>1. Real-time Monitoring</h2>
                      <ul>
                        <li>
                          PolluWatch offers real time data visualization of
                          some of the key air pollution parameters.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2">
                    <img
                      src={require("../assets/img/image3.jpg")}
                      className="img-fluid w-100 h-100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="carousel-item">
            <div className="container">
              <div className="card mb-4 mb-lg-0">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-1 mb-4 mb-lg-0">
                    <div className="mb-30">
                      <h2>2. Data Collection and Analytics</h2>
                      <ul>
                        <li>
                          Data will be collected from monitoring stations and
                          advanced analysis performed to identify trends,
                          correlations, and pollution hot spots.
                        </li>
                        <li>
                          We will utilize machine learning algorithms to
                          forecast air quality trends based on historical and
                          real-time data.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2">
                    <img
                      src={require("../assets/img/image4.jpg")}
                      className="img-fluid w-100 h-100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="carousel-item">
            <div className="container">
              <div className="card mb-4 mb-lg-0">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-1 mb-4 mb-lg-0">
                    <div className="mb-30">
                      <h2>3. Visualization and Reporting</h2>
                      <ul>
                        <li>
                          We seek to develop intuitive dashboards and
                          visualization tools to present air quality data in a
                          user-friendly manner.
                        </li>
                        <li>
                          The system will generate reports and alerts for
                          stakeholders, including government agencies,
                          environmental organizations, and the general public.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2">
                    <img
                      src={require("../assets/img/image5.png")}
                      className="img-fluid w-100 h-100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#featureCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#featureCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* Slideshow End */}
    </div>
  </div>
  )
}

export default Solution