import React from 'react';

const Navbar = () => (
  <div className="container-fluid position-relative p-0">
    <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-2 py-lg-0"> {/* Reduced bottom padding */}
      <a href="" className="navbar-brand p-0">
        <h1 className="m-0"><i className="fa fa-wind me-3"></i>PolluWatch</h1>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="fa fa-bars"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">
          <a href="/" className="nav-item nav-link active">Home</a>
          <a href="#about" className="nav-item nav-link">About</a>
          <a href="#team" className="nav-item nav-link">Team</a>
          <a href="#footer" className="nav-item nav-link">Contact</a>
        </div>
      </div>
    </nav>

    {/* Carousel Start */}
    <div className="carousel-header">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel" data-bs-interval="10000"> {/* Set interval to 10 seconds */}
        <ol className="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <img src={require('../assets/img/image1.jpg')} className="img-fluid" alt="Image" />
            <div className="carousel-caption">
              <div className="p-3" style={{ maxWidth: '900px' }}>
                <h4
                  className="text-white text-uppercase fw-bold mb-4"
                  style={{ letterSpacing: '3px' }}
                >
                  Combat Air Pollution
                </h4>
                <h1 className="display-2 text-capitalize text-white mb-4">
                  Real-time Monitoring for Healthier Living
                </h1>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../assets/img/image5.jpg')} className="img-fluid" alt="Image" />
            <div className="carousel-caption">
              <div className="p-3" style={{ maxWidth: '900px' }}>
                  <h4
                  className="text-white text-uppercase fw-bold mb-4"
                  style={{ letterSpacing: '3px' }}
                >
                  Combat Air Pollution
                </h4>
                <h1 className="display-2 text-capitalize text-white mb-4">
                  Empower Yourself with Clean Air Insights
                </h1>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon btn bg-primary"
            aria-hidden="false"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon btn bg-primary"
            aria-hidden="false"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    {/* Carousel End */}
  </div>
);

export default Navbar;
