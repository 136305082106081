import React from 'react'

function About() {
  return (
    <div className="container-fluid about py-3">
    <div className="container py-3">
      <div className="col-lg-7 mx-auto text-center mb-4">
        <h2>PolluWatch - Air Quality Monitoring Software</h2>
        <h4>Web-based Air Monitoring Software for Remote Data Access</h4>
        <p style={{ fontSize: '20px' }}></p>
      </div>
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <div
            className="h-100"
            style={{
              borderRadius: '50px',
              border: '50px solid',
              borderColor: 'transparent #13357b transparent #13357b',
            }}
          >
            <img src={require("../assets/img/image1.webp")} className="img-fluid w-100 h-100" alt="" />
          </div>
        </div>
        <div
          className="col-lg-7"
          style={{
            background: 'linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))',
            backgroundImage: `url(./assets/img/)`,
          }}
        >
          <div
            style={{
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.25)',
              padding: '15px',
              borderRadius: '3px',
            }}
          >
            <h1 className="mb-3">Understanding Our Air</h1>
            <p
              className="mb-3"
              style={{ color: 'black', fontSize: '20px', verticalAlign: 'middle' }}
            >
              Many developing countries face severe air quality issues in
              urban areas due to rapid urbanization, increased traffic,
              industrial activities, and biomass burning. Cities often have
              high levels of pollutants like PM2.5, PM10, NO2, and SO2, which
              pose significant health risks.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default About